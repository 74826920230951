import React from 'react';
import { withRouter } from 'react-router-dom';
import { Tabs } from '../Tabs';

class ClientMenu extends React.Component {

    render() {
        return (
            <div>
                {this.props.id !== "71D438AC-09EB-46FD-B34D-B0569470916A" ?
                    <Tabs
                        tabs={[{
                            url: `/clients/${this.props.id}`,
                            label: 'Client Settings'
                        }]} />
                    :
                    <Tabs
                        tabs={[{
                            url: `/clients/${this.props.id}`,
                            label: 'Client Settings'
                        }, {
                            url: `/clients/rules/${this.props.id}`,
                            label: 'Price Rules'
                        }]} />
                }
            </div>
        )
    }
};

export default withRouter(ClientMenu);