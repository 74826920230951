import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FormContainer, NarrowContainer } from '../../components/Container';
import { Title } from '../../components/Title';
import { Divider } from '../../components/Divider';
import { SubmitButton, BackButton, SwitchButton } from '../../components/Button';
import { TextInput } from '../../components/TextInput';
import UserAPI from '../../api/UserAPI';
import { Notes } from '../../components/Notes';

function UserPage({ navigation }) {

    const { id } = useParams();
    const history = useHistory();

    const [loggedInUser, setLoggedInUser] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [isSuspended, setIsSuspended] = useState(false);
    const [permCashSales, setPermCashSales] = useState(false);
    const [permPayments, setPermPayments] = useState(false);
    const [permClients, setPermClients] = useState(false);
    const [permClientProducts, setPermClientProducts] = useState(false);
    const [permProducts, setPermProducts] = useState(false);
    const [permExport, setPermExport] = useState(false);
    const [permRules, setPermRules] = useState(false);
    const [permRuleApproval, setPermRuleApproval] = useState(false);
    const [permApprove, setPermApprove] = useState(false);
    const [permReports, setPermReports] = useState(false);
    const [permUsers, setPermUsers] = useState(false);
    const [permSystem, setPermSystem] = useState(false);
    const [permCashSalesOrders, setPermCashSalesOrders] = useState(false);
    const [permCashSalesFunds, setPermCashSalesFunds] = useState(false);
    const [permCashSalesFundsHigher, setPermCashSalesFundsHigher] = useState(false);

    useEffect(() => {
        UserAPI.getUserData((data) => {
            if (data) {
                setLoggedInUser(data.userToken);
            }
        });
        if (id !== 'new') {
            loadUser();
        }
    }, []);

    const loadUser = () => {
        UserAPI.getUser(id, (data) => {
            setFirstName(data.firstName);
            setLastName(data.lastName);
            setEmailAddress(data.emailAddress);
            setPermCashSales(data.permCashSales);
            setPermPayments(data.permPayments);
            setPermClients(data.permClients);
            setPermClientProducts(data.permClientProducts);
            setPermProducts(data.permProducts);
            setPermExport(data.permExport);
            setPermRules(data.permRules);
            setPermRuleApproval(data.permRuleApproval);
            setPermApprove(data.permApprove);
            setPermReports(data.permReports);
            setPermUsers(data.permUsers);
            setPermSystem(data.permSystem);
            setPermCashSalesOrders(data.permCashSalesOrders);
            setPermCashSalesFunds(data.permCashSalesFunds);
            setPermCashSalesFundsHigher(data.permCashSalesFundsHigher);
            setIsSuspended(data.isSuspended);
        });
    }

    const deleteUser = () => {
        UserAPI.delUser(id, (data) => {
            history.push('/users');
        });
    }

    const save = () => {

        let payload = {
            id: id,
            userToken: id,
            firstName: firstName,
            lastName: lastName,
            emailAddress: emailAddress,
            permCashSales: permCashSales,
            permPayments: permPayments,
            permClients: permClients,
            permClientProducts: permClientProducts,
            permProducts: permProducts,
            permExport: permExport,
            permRules: permRules,
            permRuleApproval: permRuleApproval,
            permApprove: permApprove,
            permReports: permReports,
            permUsers: permUsers,
            permSystem: permSystem,
            permCashSalesOrders: permCashSalesOrders,
            permCashSalesFunds: permCashSalesFunds,
            permCashSalesFundsHigher: permCashSalesFundsHigher,
            isSuspended: isSuspended,
            newPass: ''
        };

        UserAPI.updUser(payload, (data) => {

            if (loggedInUser.toLowerCase() === id.toLowerCase()) {
                // reset user permissions
                UserAPI.getUser(id, (usr) => {
                    localStorage.setItem('userData', JSON.stringify(usr));
                    history.push('/users');
                });
            } else {
                history.push('/users');
            }

        });
    }

    return (
        <div className="container-fluid">
            <div className="row row-eq-height">
                <div className="col-sm-12 content">
                    <NarrowContainer>

                        <BackButton url="/users" title="Back" />
                        <Title title={id === 'new' ? "New User" : "Edit User"} />

                        <FormContainer title="User Information"
                            description={`Please add the details for this user.`}>

                            <div className="row">
                                <div className="col-sm-6">
                                    <TextInput
                                        name="firstName"
                                        type="text"
                                        label="First Name"
                                        value={firstName}
                                        maxLength={45}
                                        asterix={true}
                                        onChange={(name, value) => setFirstName(value)}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <TextInput
                                        name="lastName"
                                        type="text"
                                        label="Last Name"
                                        value={lastName}
                                        maxLength={45}
                                        asterix={true}
                                        onChange={(name, value) => setLastName(value)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <TextInput
                                        name="emailAddress"
                                        type="email"
                                        label="Email Address"
                                        value={emailAddress}
                                        maxLength={75}
                                        asterix={true}
                                        onChange={(name, value) => setEmailAddress(value)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <SwitchButton
                                        label="Suspend Account"
                                        text="Block account access?"
                                        name="isSuspended"
                                        value={isSuspended}
                                        onChange={(name, value) => setIsSuspended(value)}
                                    />
                                </div>
                            </div>

                        </FormContainer>

                        <Divider />

                        <FormContainer title="User Permissions"
                            description={`Please set the permissions for this user.`}>

                            <div className="row">
                                <div className="col-sm-12">
                                    <SwitchButton
                                        name="permCashSales"
                                        value={permCashSales}
                                        onChange={(name, value) => setPermCashSales(value)}
                                        text="User can view Cash Sales orders"
                                        width={40}
                                        height={20}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <SwitchButton
                                        name="permCashSalesOrders"
                                        value={permCashSalesOrders}
                                        onChange={(name, value) => setPermCashSalesOrders(value)}
                                        text=" User can place and edit orders - Cash Sales Portal Gift Card"
                                        width={40}
                                        height={20}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <SwitchButton
                                        name="permCashSalesFunds"
                                        value={permCashSalesFunds}
                                        onChange={(name, value) => setPermCashSalesFunds(value)}
                                        text="User can receive funds - Cash Sales Portal Gift Card - Under £5,000"
                                        width={40}
                                        height={20}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <SwitchButton
                                        name="permCashSalesFundsHigher"
                                        value={permCashSalesFundsHigher}
                                        onChange={(name, value) => setPermCashSalesFundsHigher(value)}
                                        text="User can receive funds - Cash Sales Portal Gift Card - £5,000+"
                                        width={40}
                                        height={20}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12">
                                    <SwitchButton
                                        name="permPayments"
                                        value={permPayments}
                                        onChange={(name, value) => setPermPayments(value)}
                                        text="User can add/edit/delete payment requests"
                                        width={40}
                                        height={20}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <SwitchButton
                                        name="permClients"
                                        value={permClients}
                                        onChange={(name, value) => setPermClients(value)}
                                        text="User can add/edit clients"
                                        width={40}
                                        height={20}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <SwitchButton
                                        name="permClientProducts"
                                        value={permClientProducts}
                                        onChange={(name, value) => setPermClientProducts(value)}
                                        text="User can add/edit/delete client products"
                                        width={40}
                                        height={20}
                                    />
                                </div>
                            </div>
                            {/*
                            <div className="row">
                                <div className="col-sm-12">
                                    <SwitchButton
                                        name="permProducts"
                                        value={permProducts}
                                        onChange={this.onChange.bind(this)}
                                        text="User can modify product details"
                                        width={40}
                                        height={20}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <SwitchButton
                                        name="permExport"
                                        value={permExport}
                                        onChange={this.onChange.bind(this)}
                                        text="User can customise data export structures"
                                        width={40}
                                        height={20}
                                    />
                                </div>
                            </div>
                            */}
                            <div className="row">
                                <div className="col-sm-12">
                                    <SwitchButton
                                        name="permRules"
                                        value={permRules}
                                        onChange={(name, value) => setPermRules(value)}
                                        text="User can add/edit/delete price rules"
                                        width={40}
                                        height={20}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <SwitchButton
                                        name="permReports"
                                        value={permReports}
                                        onChange={(name, value) => setPermReports(value)}
                                        text="User can access Reporting"
                                        width={40}
                                        height={20}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <SwitchButton
                                        name="permUsers"
                                        value={permUsers}
                                        onChange={(name, value) => setPermUsers(value)}
                                        text="User can add/edit/delete other users"
                                        width={40}
                                        height={20}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <SwitchButton
                                        name="permSystem"
                                        value={permSystem}
                                        onChange={(name, value) => setPermSystem(value)}
                                        text="Admin Access"
                                        width={40}
                                        height={20}
                                    />
                                </div>
                            </div>

                        </FormContainer>

                        {id !== 'new' &&
                            <Divider />
                        }

                        {id !== 'new' &&
                            <Notes id={id} />
                        }

                        <Divider />

                        <div className="row">
                            <div className="col-sm-4">
                                {id !== 'new' &&
                                    <SubmitButton
                                        text="Delete User"
                                        className="btn btn-secondary"
                                        onClick={(e) => { if (window.confirm('Are you sure you wish to cancel this user?')) deleteUser() }}
                                    />
                                }
                            </div>
                            <div className="col-sm-4" style={{ paddingBottom: 60 }}>
                                <span className="invalid-error">&nbsp;</span>
                            </div>
                            <div className="col-sm-4 text-right" style={{ paddingBottom: 60 }}>
                                <SubmitButton
                                    text={id === 'new' ? 'Create User' : 'Update User'}
                                    className="btn"
                                    onClick={save}
                                />
                            </div>
                        </div>

                    </NarrowContainer>

                </div>
            </div>

        </div>
    )

}

export default UserPage;