import React, { useEffect, useState } from 'react';
import SharedFunctions from '../../util/SharedFunctions';
import moment from 'moment';
import { Title } from '../../components/Title';
import { Container } from '../../components/Container';
import UserAPI from '../../api/UserAPI';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import ConsoleAPI from '../../api/ConsoleAPI';

function HomePage({ navigation, loggedIn }) {

    const { instance, accounts } = useMsal();
    const [unknownUser, setUnknownUser] = useState(true);
    const [error, setError] = useState('');
    const [ok, setOk] = useState('');
    const [dateReceived, setDateReceived] = useState('');
    const [lineCount, setLineCount] = useState(0);
    const [errorCount, setErrorCount] = useState(0);

    const [alertMessage1, setAlertMessage1] = useState('');
    const [alertType1, setAlertType1] = useState('');
    const [alertMessage2, setAlertMessage2] = useState('');
    const [alertType2, setAlertType2] = useState('');
    const [alertMessage3, setAlertMessage3] = useState('');
    const [alertType3, setAlertType3] = useState('');

    const loadUser = () => {

        console.log("home / accounts", accounts);

        if (accounts.length > 0) {
            UserAPI.getSSOUser(accounts[0].username, (data) => {
                if (data) {
                    setUnknownUser(false);
                    localStorage.setItem('userData', JSON.stringify(data));
                } else {
                    setUnknownUser(true);
                }
            });
        } else {
            setUnknownUser(true);
        }
    }

    const loadSettings = () => {
        ConsoleAPI.getSettings((data) => {
            console.log("settings", data);
            setError(data.errorAlert);
            setOk(data.okAlert);
            setLineCount(data.lineCount);
            setErrorCount(data.errorCount);
            setDateReceived(data.fileDate);
            setAlertMessage1(data.alertMessage1);
            setAlertType1(data.alertType1);
            setAlertMessage2(data.alertMessage2);
            setAlertType2(data.alertType2);
            setAlertMessage3(data.alertMessage3);
            setAlertType3(data.alertType3);
        });
    }

    useEffect(() => {
        loadUser();
    });

    useEffect(() => {
        loadSettings();
    }, []);

    console.log("unknownUser", unknownUser);
    console.log("loggedIn", loggedIn);

    console.log({
        dateReceived: moment(dateReceived).format("yyyy-MM-DD HH:mm"),
        now: moment().format("yyyy-MM-DD HH:mm")
    });

    return (

        <React.Fragment>
            <AuthenticatedTemplate>

                {unknownUser ?
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-6 col-sm-12 login">

                                <div className="text-center">
                                    Log in has failed.
                                    <br /><br />
                                    Please ensure that you are using @Sainsburys Single Sign On login details.
                                </div>

                            </div>
                        </div>
                    </div>
                    :
                    <>
                        <div className="container-fluid">
                            <div className="row row-eq-height">
                                <div className="col-sm-12 content">
                                    <Title title={`${SharedFunctions.getGreetingTime(moment())}, ${accounts.length > 0 && accounts[0].name}`} />

                                    <Container>

                                        <h4 className="luna-h4">Notifications</h4>

                                        {(moment(dateReceived).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD") || moment(dateReceived) > moment(moment().format("YYYY-MM-DD") + ' 05:00')) ?
                                            <div className="dashboard-notification ln-c-alert ln-c-alert--fluid ln-c-alert--error" role="alert">
                                                <div className="ln-c-alert__wrapper">
                                                    <div className="ln-c-alert__icon-wrapper">
                                                        <svg aria-label="Error" role="img" className="ln-c-alert__icon ln-c-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Error circle</title><path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm1.376-14.168c0 .048-.016.096-.016.144l-.896 5.808h-.928l-.896-5.808c0-.048-.016-.096-.016-.144 0-.72.72-1.152 1.376-1.152.656 0 1.376.432 1.376 1.152zm-.112 8.048c0 .704-.56 1.264-1.264 1.264s-1.264-.56-1.264-1.264.56-1.264 1.264-1.264 1.264.56 1.264 1.264z" fill="currentColor" fillRule="evenodd"></path></svg>
                                                    </div>
                                                    <div className="ln-c-alert__content">GCMP Billing File was not received this morning by 5am. Customer Invoicing and ATS File Generation will have been affected.</div>
                                                </div>
                                            </div>
                                            : (moment(dateReceived).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") && moment(dateReceived) > moment(moment().format("YYYY-MM-DD") + ' 02:00')) ?
                                                <div className="dashboard-notification ln-c-alert ln-c-alert--fluid ln-c-alert--error" role="alert">
                                                    <div className="ln-c-alert__wrapper">
                                                        <div className="ln-c-alert__icon-wrapper">
                                                            <svg aria-label="Error" role="img" className="ln-c-alert__icon ln-c-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Error circle</title><path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm1.376-14.168c0 .048-.016.096-.016.144l-.896 5.808h-.928l-.896-5.808c0-.048-.016-.096-.016-.144 0-.72.72-1.152 1.376-1.152.656 0 1.376.432 1.376 1.152zm-.112 8.048c0 .704-.56 1.264-1.264 1.264s-1.264-.56-1.264-1.264.56-1.264 1.264-1.264 1.264.56 1.264 1.264z" fill="currentColor" fillRule="evenodd"></path></svg>
                                                        </div>
                                                        <div className="ln-c-alert__content">GCMP Billing File was not received this morning by 2am. Customer Invoicing may have been affected.</div>
                                                    </div>
                                                </div>
                                                : errorCount > 0 ?
                                                    <div className="dashboard-notification amber-notification ln-c-alert ln-c-alert--fluid ln-c-alert--error" role="alert">
                                                        <div className="ln-c-alert__wrapper">
                                                            <div className="ln-c-alert__icon-wrapper">
                                                                <svg aria-label="Error" role="img" className="ln-c-alert__icon ln-c-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Error circle</title><path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm1.376-14.168c0 .048-.016.096-.016.144l-.896 5.808h-.928l-.896-5.808c0-.048-.016-.096-.016-.144 0-.72.72-1.152 1.376-1.152.656 0 1.376.432 1.376 1.152zm-.112 8.048c0 .704-.56 1.264-1.264 1.264s-1.264-.56-1.264-1.264.56-1.264 1.264-1.264 1.264.56 1.264 1.264z" fill="currentColor" fillRule="evenodd"></path></svg>
                                                            </div>
                                                            <div className="ln-c-alert__content">GCMP Billing File was received at {moment(dateReceived).format("HH:mm")} and contained {lineCount + errorCount} Order Lines, {lineCount} processed, {errorCount} rejected.</div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="ok-notification ln-c-alert ln-c-alert--fluid ln-c-alert--error" role="alert">
                                                        <div className="ln-c-alert__wrapper">
                                                            <div className="ln-c-alert__content">GCMP Billing File received at {moment(dateReceived).format("HH:mm")} and contained {lineCount} Order Lines.</div>
                                                        </div>
                                                    </div>
                                        }

                                        {error &&
                                            <div className="dashboard-notification ln-c-alert ln-c-alert--fluid ln-c-alert--error" role="alert">
                                                <div className="ln-c-alert__wrapper">
                                                    <div className="ln-c-alert__icon-wrapper">
                                                        <svg aria-label="Error" role="img" className="ln-c-alert__icon ln-c-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Error circle</title><path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm1.376-14.168c0 .048-.016.096-.016.144l-.896 5.808h-.928l-.896-5.808c0-.048-.016-.096-.016-.144 0-.72.72-1.152 1.376-1.152.656 0 1.376.432 1.376 1.152zm-.112 8.048c0 .704-.56 1.264-1.264 1.264s-1.264-.56-1.264-1.264.56-1.264 1.264-1.264 1.264.56 1.264 1.264z" fill="currentColor" fillRule="evenodd"></path></svg>
                                                    </div>
                                                    <div className="ln-c-alert__content">{error}</div>
                                                </div>
                                            </div>
                                        }

                                        {ok &&
                                            <div className="ok-notification ln-c-alert ln-c-alert--fluid ln-c-alert--error" role="alert">
                                                <div className="ln-c-alert__wrapper">
                                                    <div className="ln-c-alert__content">{ok}</div>
                                                </div>
                                            </div>
                                        }

                                        {alertMessage1 &&
                                            <div className={`ln-c-alert ln-c-alert--fluid ln-c-alert--error ${alertType1 === 'red' ? 'dashboard-notification' : alertType1 === 'amber' ? 'dashboard-notification amber-notification' : 'ok-notification'}`} role="alert">
                                                <div className="ln-c-alert__wrapper">
                                                    {alertType1 !== 'green' &&
                                                        <div className="ln-c-alert__icon-wrapper">
                                                            <svg aria-label="Error" role="img" className="ln-c-alert__icon ln-c-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Error circle</title><path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm1.376-14.168c0 .048-.016.096-.016.144l-.896 5.808h-.928l-.896-5.808c0-.048-.016-.096-.016-.144 0-.72.72-1.152 1.376-1.152.656 0 1.376.432 1.376 1.152zm-.112 8.048c0 .704-.56 1.264-1.264 1.264s-1.264-.56-1.264-1.264.56-1.264 1.264-1.264 1.264.56 1.264 1.264z" fill="currentColor" fillRule="evenodd"></path></svg>
                                                        </div>
                                                    }
                                                    <div className="ln-c-alert__content">{alertMessage1}</div>
                                                </div>
                                            </div>
                                        }

                                        {alertMessage2 &&
                                            <div className={`ln-c-alert ln-c-alert--fluid ln-c-alert--error ${alertType2 === 'red' ? 'dashboard-notification' : alertType2 === 'amber' ? 'dashboard-notification amber-notification' : 'ok-notification'}`} role="alert">
                                                <div className="ln-c-alert__wrapper">
                                                    {alertType2 !== 'green' &&
                                                        <div className="ln-c-alert__icon-wrapper">
                                                            <svg aria-label="Error" role="img" className="ln-c-alert__icon ln-c-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Error circle</title><path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm1.376-14.168c0 .048-.016.096-.016.144l-.896 5.808h-.928l-.896-5.808c0-.048-.016-.096-.016-.144 0-.72.72-1.152 1.376-1.152.656 0 1.376.432 1.376 1.152zm-.112 8.048c0 .704-.56 1.264-1.264 1.264s-1.264-.56-1.264-1.264.56-1.264 1.264-1.264 1.264.56 1.264 1.264z" fill="currentColor" fillRule="evenodd"></path></svg>
                                                        </div>
                                                    }
                                                    <div className="ln-c-alert__content">{alertMessage2}</div>
                                                </div>
                                            </div>
                                        }

                                        {alertMessage3 &&
                                            <div className={`ln-c-alert ln-c-alert--fluid ln-c-alert--error ${alertType3 === 'red' ? 'dashboard-notification' : alertType3 === 'amber' ? 'dashboard-notification amber-notification' : 'ok-notification'}`} role="alert">
                                                <div className="ln-c-alert__wrapper">
                                                    {alertType3 !== 'green' &&
                                                        <div className="ln-c-alert__icon-wrapper">
                                                            <svg aria-label="Error" role="img" className="ln-c-alert__icon ln-c-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Error circle</title><path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm1.376-14.168c0 .048-.016.096-.016.144l-.896 5.808h-.928l-.896-5.808c0-.048-.016-.096-.016-.144 0-.72.72-1.152 1.376-1.152.656 0 1.376.432 1.376 1.152zm-.112 8.048c0 .704-.56 1.264-1.264 1.264s-1.264-.56-1.264-1.264.56-1.264 1.264-1.264 1.264.56 1.264 1.264z" fill="currentColor" fillRule="evenodd"></path></svg>
                                                        </div>
                                                    }
                                                    <div className="ln-c-alert__content">{alertMessage3}</div>
                                                </div>
                                            </div>
                                        }

                                    </Container>

                                </div>
                            </div>
                        </div>
                    </>
                }
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6 col-sm-12 login">
                            <div className="text-center">
                                <button className="ln-c-button ln-c-button--filled" onClick={() => instance.loginPopup()}>Login</button>
                            </div>
                            
                            <div className="row">
                            <div className="col-md-12 text-center" style={{paddingTop: 60}}>

Version 2.4.0
                            </div>
                            </div>

                        </div>
                    </div>
                </div>
            </UnauthenticatedTemplate>
        </React.Fragment>


    )

}

export default HomePage;
