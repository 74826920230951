import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import HomePage from '../containers/Home';
import PaymentsPage from '../containers/Payments';
import PaymentPage from '../containers/Payment';
import PaymentEditPage from '../containers/PaymentEdit';
import PaymentFundsPage from '../containers/PaymentFunds';
import ReportsPage from '../containers/Reports';
import OrdersPage from '../containers/Orders';
import OrderPage from '../containers/Order';
import OrderEditPage from '../containers/OrderEdit';
import OrderFundsPage from '../containers/OrderFunds';
import UsersPage from '../containers/Users';
import UserPage from '../containers/User';
import ClientsPage from '../containers/Clients';
import ClientPage from '../containers/Client';
import ClientExportPage from '../containers/ClientExport';
import ClientLogsPage from '../containers/ClientLogs';
import ClientOrdersPage from '../containers/ClientOrders';
import ClientRulesPage from '../containers/ClientRules';
import RulesPage from '../containers/Rules';
import RulePage from '../containers/Rule';
import SettingsPage from '../containers/Settings';
import { useIsAuthenticated } from "@azure/msal-react";

function Routes({ navigation, loggedIn = false }) {

  const isAuthenticated = useIsAuthenticated();

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      !isAuthenticated
        ? <Redirect to='/' />
        : <Component navigation={navigation} props={props} loggedIn={loggedIn} />
    )} />
  )

  return (
    <Switch>
      <Route exact path="/" component={HomePage} />
      <PrivateRoute exact path="/payments" component={PaymentsPage} />
      <PrivateRoute exact path="/payments/:id" component={PaymentPage} />
      <PrivateRoute exact path="/payments/edit/:id" component={PaymentEditPage} />
      <PrivateRoute exact path="/payments/funds/:id" component={PaymentFundsPage} />
      <PrivateRoute exact path="/reports" component={ReportsPage} />
      <PrivateRoute exact path="/orders" component={OrdersPage} />
      <PrivateRoute exact path="/orders/:id" component={OrderPage} />
      <PrivateRoute exact path="/orders/edit/:id" component={OrderEditPage} />
      <PrivateRoute exact path="/orders/funds/:id" component={OrderFundsPage} />
      <PrivateRoute exact path="/users" component={UsersPage} />
      <PrivateRoute exact path="/users/:id" component={UserPage} />
      <PrivateRoute exact path="/clients" component={ClientsPage} />
      <PrivateRoute exact path="/clients/:id" component={ClientPage} />
      <PrivateRoute exact path="/clients/export/:id" component={ClientExportPage} />
      <PrivateRoute exact path="/clients/logs/:id" component={ClientLogsPage} />
      <PrivateRoute exact path="/clients/rules/:id" component={ClientRulesPage} />
      <PrivateRoute exact path="/clients/orders/:id" component={ClientOrdersPage} />
      <PrivateRoute exact path="/rules" component={RulesPage} />
      <PrivateRoute exact path="/rules/:id" component={RulePage} />
      <PrivateRoute exact path="/settings" component={SettingsPage} />
    </Switch>
  )
}

export default Routes;