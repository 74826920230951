import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import UserAPI from '../../api/UserAPI';
import { SwitchButton } from '../../components/Button';
import { TextInput, TextArea } from '../../components/TextInput';
import { SelectBox } from '../../components/SelectBox';
import ConsoleAPI from '../../api/ConsoleAPI';
import { useMsal } from "@azure/msal-react";
import { MainLink } from '../Menu';

//const request = require("superagent");
//const AWSPS = require("../../util/AWSPS");

function Header({ route, navigation, loggedIn = false }) {

    const { accounts } = useMsal();
    const [rules, setRules] = useState(false);
    const [cashSales, setCashSales] = useState(false);
    const [reports, setReports] = useState(false);
    const [users, setUsers] = useState(false);
    const [clients, setClients] = useState(false);
    const [payments, setPayments] = useState(false);
    const [settings, setSettings] = useState(false);
    const [modal, setModal] = useState(false);
    const [priority, setPriority] = useState('');
    const [impact, setImpact] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [system, setSystem] = useState('');
    const [comments, setComments] = useState('');
    const [sendToPPS, setSendToPPS] = useState(true);
    const [sendToVEX, setSendToVEX] = useState(true);
    const [subject, setSubject] = useState('');
    const [confirmation, setConfirmation] = useState(false);
    const [incidentNumber, setIncidentNumber] = useState('');
    const [errEmail, setErrEmail] = useState('');
    const [errName, setErrName] = useState('');
    const [errSystem, setErrSystem] = useState('');
    const [errPriority, setErrPriority] = useState('');
    const [errImpact, setErrImpact] = useState('');
    const [errSubject, setErrSubject] = useState('');
    const [errComments, setErrComments] = useState('');
    const [incidentUrl, setIncidentUrl] = useState('');
    const [errCategory, setErrCategory] = useState('');
    const [category, setCategory] = useState('');
    const [submitting, setSubmitting] = useState(false);

    const setField = (fieldname, value) => {
        console.log("setField");
        setErrEmail('');
        setErrName('');
        setErrSystem('');
        setErrPriority('');
        setErrImpact('');
        setErrSubject('');
        setErrComments('');
        setErrCategory('');
        if (fieldname === "email") {
            setEmail(value);
        } else if (fieldname === "name") {
            setName(value);
        } else if (fieldname === "priority") {
            setPriority(value);
        } else if (fieldname === "impact") {
            setImpact(value);
        } else if (fieldname === "subject") {
            setSubject(value);
        } else if (fieldname === "system") {
            setSystem(value);
        } else if (fieldname === "comments") {
            setComments(value);
        } else if (fieldname === "category") {
            setCategory(value);
        }
    }

    const loadUser = () => {
        UserAPI.getUserData((data) => {
            if (data) {
                setEmail(data.emailAddress);
                setName(data.firstName + ' ' + data.lastName);
                setRules(data.permRules);
                setCashSales(data.permCashSales);
                setReports(data.permReports);
                setUsers(data.permUsers);
                setClients(data.permClients);
                setPayments(data.permPayments);
                setSettings(data.permSystem);
            } else {
                if (accounts.length > 0) {
                    UserAPI.getSSOUser(accounts[0].username, (data) => {
                        if (data) {
                            localStorage.setItem('userData', JSON.stringify(data));
                            setRules(data.permRules);
                            setCashSales(data.permCashSales);
                            setReports(data.permReports);
                            setUsers(data.permUsers);
                            setClients(data.permClients);
                            setSettings(data.permSystem);
                        }
                    });
                }
            }
        });
    }

    useEffect(() => {
        if (loggedIn) {
            loadUser();
        }
    });

    const loadIncident = () => {
        setConfirmation(false);
        setModal(true);
    }

    const hideIncident = () => {
        setErrEmail('');
        setErrName('');
        setErrSystem('');
        setErrPriority('');
        setErrImpact('');
        setErrSubject('');
        setErrComments('');
        setEmail('');
        setName('');
        setPriority('');
        setImpact('');
        setSubject('');
        setComments('');
        setSystem('');
        setSendToPPS(true);
        setSendToVEX(true);
        setCategory('');
        setErrCategory('');

        setConfirmation(false);
        setModal(false);
    }

    const postIncident = async () => {

        setSubmitting(true);

        var ok = true;

        if (!email) {
            setErrEmail('Please enter your email address');
            ok = false;
        }
        if (!name) {
            setErrName('Please enter your name');
            ok = false;
        }
        if (!system) {
            setErrSystem('Please select a system');
            ok = false;
        }
        if (!impact) {
            setErrImpact('Please select an impact level');
            ok = false;
        }
        if (!priority) {
            setErrPriority('Please select a priority level');
            ok = false;
        }
        if (!subject) {
            setErrSubject('Please enter your short description');
            ok = false;
        }
        if (!subject || subject.length < 20) {
            setErrSubject('Short description must be at least 20 characters');
            ok = false;
        }
        if (!comments) {
            setErrComments('Please enter your comments');
            ok = false;
        }
        if (!category) {
            setErrCategory('Please select a category');
            ok = false;
        }
        let subjectLength = (category + ": " + subject).length;
        if (subjectLength > 50) {
            if ((subjectLength - 50) === 1) {
                setErrSubject('Short Description Including Category is too long by 1 character');
            } else {
                setErrSubject('Short Description Including Category is too long by ' + (subjectLength - 50) + ' characters');
            }
            ok = false;
        }

        if (ok) {
            let payload = {
                name: name,
                email: email,
                system: system,
                priority: priority,
                impact: impact,
                subject: subject,
                comments: comments,
                description: comments, // was description,
                sendToPPS: sendToPPS && system === "CHOPIN",
                sendToVEX: sendToVEX && system === "GCMP",
                category: category
            }

            console.log(payload);

            ConsoleAPI.logIncident(payload, (data) => {
                console.log(data);
                if (data.Message) {
                    alert(data.Message);
                } else {
                    const splits = data.result[0].record_link.split("/");
                    let snUrl = process.env.REACT_APP_DPI_ENV === 'development' ? `https://sainsburystest.service-now.com/assist?id=issue_details&table=incident&sys_id=` : `https://sainsburys.service-now.com/assist?id=issue_details&table=incident&sys_id=`;
                    snUrl = snUrl + splits[splits.length - 1];
                    setIncidentUrl(snUrl);
                    setConfirmation(true);
                    setIncidentNumber(data.result[0].display_value);
                }
                setSubmitting(false);

            });

        } else {
            setSubmitting(false);
        }

    }

    const location = useLocation();
    const path = location.pathname;

    return (
        <header>
            <div className="main-container">
                <div className="left-column logo">
                    <a href="/"><img alt="" src="/assets/img/logo.png" /></a>
                </div>
                <div className="center-column">
                    <h3 className="header h3 luna-title">Gifting Account &amp; Transaction Environment</h3>
                </div>
                <div className="end-column">
                    <button className="ln-c-button ln-c-button--filled" onClick={loadIncident}>Log Incident</button>
                </div>

                {modal &&
                    <>
                        <div className="modal-shadow"></div>
                        <div className="incident-modal">
                            <div className="ms-close">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" onClick={hideIncident}>
                                    <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clip-rule="evenodd" />
                                </svg>

                            </div>
                            <div className="incident-form">

                                {!confirmation &&
                                    <>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <TextInput
                                                    name="name"
                                                    type="text"
                                                    label="Your Name"
                                                    value={name}
                                                    maxLength={45}
                                                    asterix={true}
                                                    onChange={(name, value) => setField(name, value)}
                                                    error={errName}
                                                />
                                            </div>
                                            <div className="col-sm-6">
                                                <TextInput
                                                    name="email"
                                                    type="email"
                                                    label="Your Email"
                                                    value={email}
                                                    maxLength={45}
                                                    asterix={true}
                                                    onChange={(name, value) => setField(name, value)}
                                                    error={errEmail}
                                                />
                                            </div>
                                            <div className="col-sm-12">
                                                <SelectBox
                                                    name="system"
                                                    label="System"
                                                    placeholder="Select System"
                                                    value={system}
                                                    maxLength={45}
                                                    options={[{
                                                        value: 'CHOPIN',
                                                        label: 'Card Holder Online Personalisation Network (CHOPIN)'
                                                    }, {
                                                        value: 'GCMP',
                                                        label: 'Gift Card Management Platform (GCMP)'
                                                    }, {
                                                        value: 'GATE',
                                                        label: 'Gifting Account & Transaction Environment (GATE)'
                                                    }, {
                                                        value: 'JDE',
                                                        label: 'JDE (JD Edwards)'
                                                    }, {
                                                        value: 'PAYWARE',
                                                        label: 'Payware (Electronic Gift Card)'
                                                    }, {
                                                        value: 'AFB',
                                                        label: 'argosforbusiness.co.uk'
                                                    }]}
                                                    onChange={(name, value) => setField(name, value)}
                                                    error={errSystem}
                                                />
                                            </div>

                                            {system === "CHOPIN" &&
                                                <div className="col-sm-12">
                                                    <SwitchButton
                                                        text="Send to PPS Helpdesk"
                                                        name="sendToPPS"
                                                        value={sendToPPS}
                                                        onChange={(name, value) => setSendToPPS(value)}
                                                    />
                                                </div>
                                            }

                                            {system === "GCMP" &&
                                                <div className="col-sm-12">
                                                    <SwitchButton
                                                        text="Send to GCMP Helpdesk"
                                                        name="sendToVEX"
                                                        value={sendToVEX}
                                                        onChange={(name, value) => setSendToVEX(value)}
                                                    />
                                                </div>
                                            }


                                            <div className="col-sm-6">
                                                <SelectBox
                                                    name="priority"
                                                    label="Priority"
                                                    placeholder="Select Priority"
                                                    value={priority}
                                                    maxLength={45}
                                                    options={[{
                                                        value: '4',
                                                        label: 'Low'
                                                    }, {
                                                        value: '3',
                                                        label: 'Medium'
                                                    }, {
                                                        value: '2',
                                                        label: 'High'
                                                    }, {
                                                        value: '1',
                                                        label: 'Critical'
                                                    }]}
                                                    onChange={(name, value) => setField(name, value)}
                                                    error={errPriority}
                                                />
                                            </div>
                                            <div className="col-sm-6">
                                                <SelectBox
                                                    name="impact"
                                                    label="Impact"
                                                    placeholder="Select Impact"
                                                    value={impact}
                                                    maxLength={45}
                                                    options={[{
                                                        value: '4',
                                                        label: 'Minor/Low'
                                                    }, {
                                                        value: '3',
                                                        label: 'Moderate/Limited'
                                                    }, {
                                                        value: '2',
                                                        label: 'Significant/Large'
                                                    }, {
                                                        value: '1',
                                                        label: 'Extensive/Widespread'
                                                    }]}
                                                    onChange={(name, value) => setField(name, value)}
                                                    error={errImpact}
                                                />
                                            </div>

                                            <div className="col-sm-6">
                                                <SelectBox
                                                    name="category"
                                                    label="Category"
                                                    placeholder="Select Category"
                                                    value={category}
                                                    maxLength={45}
                                                    options={[
                                                        { value: "Account Issue", label: "Account Issue" },
                                                        { value: "Order Issue", label: "Order Issue" },
                                                        { value: "Card Issue", label: "Card Issue" },
                                                        { value: "Payment Issue", label: "Payment Issue" },
                                                        { value: "Bulk Cancel", label: "Bulk Cancel" },
                                                        { value: "Invoice/Credit", label: "Invoice/Credit" },
                                                        { value: "Billing/Discount", label: "Billing/Discount" },
                                                        { value: "User/Access", label: "User/Access" },
                                                        { value: "API Issue", label: "API Issue" },
                                                        { value: "File Issue", label: "File Issue" },
                                                        { value: "Report Issue", label: "Report Issue" },
                                                        { value: "Finance Issue", label: "Finance Issue" }]}
                                                    onChange={(name, value) => setField(name, value)}
                                                    error={errCategory}
                                                />
                                            </div>

                                            <div className="col-sm-12">
                                                <TextInput
                                                    name="subject"
                                                    type="text"
                                                    label="Short Description"
                                                    value={subject}
                                                    asterix={true}
                                                    onChange={(name, value) => setField(name, value)}
                                                    error={errSubject}
                                                />
                                            </div>
                                            <div className="col-sm-12">
                                                <TextArea
                                                    name="comments"
                                                    type="textarea"
                                                    label="Describe Your Incident"
                                                    value={comments}
                                                    maxLength={8000}
                                                    rows={5}
                                                    asterix={true}
                                                    onChange={(name, value) => setField(name, value)}
                                                    error={errComments}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 py-4">
                                                <button disabled={submitting} className="ln-c-button ln-c-button--filled" onClick={postIncident}>Submit</button>
                                            </div>
                                        </div>
                                    </>
                                }
                                {confirmation &&
                                    <>

                                        <div className="row">
                                            <div className="col-sm-12 py-4">
                                                <p className='text-center'>Thank you, your incident has been logged under incident number: <a className="incident-link" target="_blank" rel="noopener noreferrer" href={incidentUrl}>{incidentNumber}</a></p>
                                                <p className='text-center'>Please click the incident number link above to attach any required documents.</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 py-4">
                                                <button className="ln-c-button ln-c-button--filled" onClick={hideIncident}>Close</button>
                                            </div>
                                        </div>
                                    </>
                                }

                            </div>
                        </div>
                    </>
                }

                {loggedIn &&
                    <div className="sub-nav">

                        <nav>
                            <ul>
                                <li className={path === '/' ? 'active' : ''}><MainLink title="Dashboard" icon="fa-home" url="/" /></li>
                                {clients && <li className={(path === '/clients' || path.indexOf("clients/") > -1) ? 'active' : ''}><MainLink title="Clients" icon="fa-users" url="/clients" /></li>}
                                {rules && <li className={(path === '/rules' || path.indexOf("rules/") > -1) ? 'active' : ''}><MainLink title="Price Rules" icon="fa-percentage" url="/rules" /></li>}
                                {cashSales && <li className={(path === '/orders' || path.indexOf("orders/") > -1) ? 'active' : ''}><MainLink title="Cash Sales" icon="fa-tags" url="/orders" /></li>}
                                {payments && <li className={(path === '/payments' || path.indexOf("payments/") > -1) ? 'active' : ''}><MainLink title="Payments" icon="fa-credit-card" url="/payments" /></li>}
                                {reports && <li className={(path === '/reports' || path.indexOf("reports/") > -1) ? 'active' : ''}><MainLink title="Reports" icon="fa-users" url="/reports" /></li>}
                                {users && <li className={(path === '/users' || path.indexOf("users/") > -1) ? 'active' : ''}><MainLink title="Users" icon="fa-users" url="/users" /></li>}
                                {settings && <li className={(path === '/settings' || path.indexOf("settings/") > -1) ? 'active' : ''}><MainLink title="Admin" icon="fa-users" url="/settings" /></li>}
                            </ul>
                        </nav>

                        <div className="sub-nav-version">
                            Version: 2.4.0
                        </div>
                        <div className="sub-nav-text">
                            {accounts.length > 0 && accounts[0].name}
                        </div>
                    </div>
                }
            </div>
        </header>
    )
};

export default Header;