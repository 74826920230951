import React from 'react';
import { withRouter } from 'react-router-dom';
import ProductAPI from '../../api/ProductAPI';
import { Loader } from '../Loader';

class CategoryTree extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            parent: props.parent ? props.parent : 0,
            rule: props.rule ? props.rule : 0,
            setToken: props.setToken ? props.setToken : '00000000-0000-0000-0000-000000000000',
            tree: [],
            isLoaded: false,
            isTradingCategory: props.isTradingCategory ? props.isTradingCategory : false
        }

        this.selectItem = this.selectItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.selectNone = this.selectNone.bind(this);

    }

    setFolder(id) {
        this.setState({
            parent: id
        }, () => {
            this.loadTree();
        })
    }

    componentDidMount() {
        this.loadTree();
    }

    loadTree() {
        ProductAPI.getTree(this.state.parent, this.state.rule, this.state.setToken, this.state.isTradingCategory, (data) => {
            this.setState({
                tree: data,
                isLoaded: true,
            })
        });
    }

    selectItem(id) {
        console.log("selectItem()", id);
        this.setState({
            isLoaded: false
        }, () => {
            ProductAPI.updTree(id, "A", this.state.rule, this.state.setToken, (data) => {
                this.loadTree();
            });
        });
    }

    selectAll() {
        console.log("selectAll()");
        var items = "";
        for (var i = 0, len = this.state.tree.length; i < len; i++) {
            if (i > 0) {
                items += ",";
            }
            items += this.state.tree[i].ID;
        }
        this.setState({
            isLoaded: false
        }, () => {
            ProductAPI.updTreeMultiple(items, "A", this.state.rule, this.state.setToken, (data) => {
                this.loadTree();
            });
        });
    }

    selectNone() {
        console.log("selectNone()");
        var items = "";
        for (var i = 0, len = this.state.tree.length; i < len; i++) {
            if (i > 0) {
                items += ",";
            }
            items += this.state.tree[i].ID;
        }
        this.setState({
            isLoaded: false
        }, () => {
            ProductAPI.updTreeMultiple(items, "D", this.state.rule, this.state.setToken, (data) => {
                this.loadTree();
            });
        });
    }

    removeItem(id) {
        console.log("removeItem()", id);
        this.setState({
            isLoaded: false
        }, () => {
            ProductAPI.updTreeMultiple(id, "D", this.state.rule, this.state.setToken, (data) => {
                this.loadTree();
            });
        });
    }

    render() {
        return (
            <div>
                {this.state.isLoaded ?
                    <div>
                        <table className="tree">
                            <tbody>
                                {this.state.tree.map((item, index) =>
                                    <tr key={index}>
                                        <td width="5%">
                                            {item.selected ?
                                                <div style={{ cursor: 'pointer' }} onClick={() => this.removeItem(item.ID)} title="Add"><i className="far fa-check-circle"></i></div>
                                                :
                                                <div style={{ cursor: 'pointer' }} onClick={() => this.selectItem(item.ID)} title="Add"><i className="far fa-circle"></i></div>
                                            }
                                        </td>
                                        <td width="5%">{item.folder ? <div style={{ cursor: 'pointer' }} onClick={() => this.setFolder(item.ID)}><i className="fa fa-folder"></i></div> : <i className="fa fa-cube"></i>}</td>
                                        <td width="90%" className="text-cell">{item.folder ? <div style={{ cursor: 'pointer' }} onClick={() => this.setFolder(item.ID)}>{item.name}</div> : item.name}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <p><em><span style={{ cursor: 'pointer' }} className="small-link" onClick={() => this.selectAll()}>Select all</span> / <span style={{ cursor: 'pointer' }} className="small-link" onClick={() => this.selectNone()}>Deselect all</span></em></p>
                    </div>


                    :
                    <Loader />
                }
            </div>
        )
    }
};

export default withRouter(CategoryTree);