import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import { FormContainer, NarrowContainer } from '../../components/Container';
import { Title } from '../../components/Title';
import { Divider } from '../../components/Divider';
import { SubmitButton, BackButton } from '../../components/Button';
import { TextInput, DiscountInput } from '../../components/TextInput';
import ConsoleAPI from '../../api/ConsoleAPI';
import UserAPI from '../../api/UserAPI';
import ClientAPI from '../../api/ClientAPI';
import { CategoryTree } from '../../components/CategoryTree';
import { ClientMenu } from '../../components/Menu';

function ClientPage({ navigation }) {

    const { id } = useParams();
    const history = useHistory();

    const [permClientProducts, setPermClientProducts] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);
    const [setName, setSetName] = useState('');
    const [stockSource, setStockSource] = useState('03281E26-4BA2-42FF-A5B5-17720F128700');
    const [businessAccNo, setBusinessAccNo] = useState('');
    const [contactCountry, setContactCountry] = useState('');
    const [isGroup, setIsGroup] = useState('');

    const [errSetName, seterrSetName] = useState('');
    const [historyData, setHistory] = useState([]);
    const [discountRates, setDiscountRates] = useState([]);
    const [productId, setProductId] = useState(null);
    const [rate, setDiscountRate] = useState(0);
    const [error, setError] = useState('');

    const loadHistory = () => {
        ConsoleAPI.getHistory(id, (data) => {
            console.log(data);
            setHistory(data);
        })
    }

    const loadDiscountRates = () => {
        ConsoleAPI.getDiscountRates(id, (data) => {
            console.log(data);
            setDiscountRates(data);
        })
    }

    const editProduct = (id, rate) => {
        setProductId(id);
        setDiscountRate(rate);
        setError('');
    }

    useEffect(() => {
        loadUser();
        if (id !== 'new') {
            loadClient();
            loadHistory();
            loadDiscountRates();
        }
    }, []);

    const loadUser = () => {
        UserAPI.getUserData((data) => {
            if (data) {
                setPermClientProducts(data.permClientProducts);
            }
        })
    }

    const loadClient = () => {
        ClientAPI.getClient(id, (data) => {
            setSetName(data.setName);
            setStockSource(data.stockSource);
            setBusinessAccNo(data.businessAccNo);
            setContactCountry(data.contactCountry);
            setIsGroup(data.isGroup);
        });
    }

    const saveRate = () => {
        if (rate <= 10 && rate >= 0) {
            ConsoleAPI.updDiscountRate(id, productId, rate, (data) => {
                setProductId(null);
                setDiscountRate(0);
                loadDiscountRates();
                loadHistory();
            });
        } else {
            setError('Discount rate must be between 0% and 10%');
        }
    }

    const save = () => {

        var _ok = true;
        if (setName === '') {
            _ok = false;
            setHasErrors(true);
            seterrSetName('Please enter a client name');
        }

        if (_ok) {

            if (id === 'new') {

                var _payload = {
                    setToken: '',
                    setName: setName,
                    setCurrency: 1,
                    businessAccNo: parseInt(businessAccNo),
                    stockSource: stockSource,
                    outputXml: false,
                    outputJson: false,
                    outputCsv: false,
                    outputCustom: false,
                    outputCustomXls: false,
                    outputFileName: '',
                    ftpHost: '',
                    ftpUser: '',
                    ftpPass: '',
                    emailRecipients: '',
                    excInternetOnly: false,
                    excDeliveryOnly: false,
                    livePricing: false,
                    protocolID: 0,
                    remoteFolder: '',
                    excInStock: false,
                    excOutOfStock: false,
                    excLimitedStock: false,
                    isGroup: false,
                    groupKey: '',
                    retailFrom: 0,
                    retailTo: 0,
                    sellingFrom: 0,
                    sellingTo: 0,
                    compression: false,
                    encData: false,
                    encKey: '',
                    orderFTPAccess: false,
                    orderFTPFileID: '',
                    orderFTPUser: '',
                    orderFTPPass: '',
                    orderFTPIP: 0,
                    orderFTPActive: false,
                    orderFilePrefix: '',
                    orderAddDelivery: false,
                    orderReportRecipients: '',
                    ftpPortNumber: 0,
                    isNonDPR: false,
                    rejectionMethod: 1,
                    orderSuccessReports: false,
                    incProductRemovals: false,
                    validatePrice: false,
                    acceptClientPrice: false,
                    retryDays: 0,
                    stockFeed: false,
                    stockFeedFileName: '',
                    stockFeedProtocol: 0,
                    stockFeedHost: '',
                    stockFeedFolder: '',
                    stockFeedUser: '',
                    stockFeedPass: '',
                }

                ClientAPI.updClient(_payload, (data) => {
                    history.push(`/clients`);
                })

            } else {

                var _payloadx = {
                    setToken: id === 'new' ? '' : id,
                    setName: setName,
                    stockSource: stockSource,
                    businessAccNo: parseInt(businessAccNo),
                    setCurrency: 1,
                    contactCountry: contactCountry,
                    isGroup: isGroup,
                    outputXml: false,
                    outputJson: false,
                    outputCsv: false,
                    outputCustom: false,
                    outputCustomXls: false,
                    outputFileName: '',
                    ftpHost: '',
                    ftpUser: '',
                    ftpPass: '',
                    emailRecipients: '',
                    excInternetOnly: false,
                    excDeliveryOnly: false,
                    livePricing: false,
                    protocolID: 0,
                    remoteFolder: '',
                    excInStock: false,
                    excOutOfStock: false,
                    excLimitedStock: false,
                    groupKey: '',
                    retailFrom: 0,
                    retailTo: 0,
                    sellingFrom: 0,
                    sellingTo: 0,
                    compression: false,
                    encData: false,
                    encKey: '',
                    orderFTPAccess: false,
                    orderFTPFileID: '',
                    orderFTPUser: '',
                    orderFTPPass: '',
                    orderFTPIP: 0,
                    orderFTPActive: false,
                    orderFilePrefix: '',
                    orderAddDelivery: false,
                    orderReportRecipients: '',
                    ftpPortNumber: 0,
                    isNonDPR: false,
                    rejectionMethod: 1,
                    orderSuccessReports: false,
                    incProductRemovals: false,
                    validatePrice: false,
                    acceptClientPrice: false,
                    retryDays: 0,
                    stockFeed: false,
                    stockFeedFileName: '',
                    stockFeedProtocol: 0,
                    stockFeedHost: '',
                    stockFeedFolder: '',
                    stockFeedUser: '',
                    stockFeedPass: '',

                }

                ClientAPI.updClient(_payloadx, (data) => {
                    history.push(`/clients`);
                });

            }

        }

    }

    return (
        <div className="container-fluid">
            <div className="row row-eq-height">
                <div className="col-sm-12 content">
                    <NarrowContainer>

                        <BackButton url={'/clients'} title="Back" />
                        <Title title={id === 'new' ? "New Client" : "Edit Client"} />

                        <ClientMenu id={id} />

                        <FormContainer title="Client Information"
                            description={`Please enter the client details.`}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <TextInput
                                        name="setName"
                                        type="text"
                                        label="Client Name"
                                        value={setName}
                                        maxLength={45}
                                        asterix={true}
                                        onChange={(name, value) => setSetName(value)}
                                        error={errSetName}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <TextInput
                                        name="businessAccNo"
                                        type="text"
                                        label="Account No."
                                        value={businessAccNo}
                                        maxLength={45}
                                        asterix={true}
                                        onChange={(name, value) => setBusinessAccNo(value)}
                                    />
                                </div>
                            </div>
                            {/*
                            <div className="row">
                            <div className="col-sm-6">
                                    <SelectBox
                                        name="stockSource"
                                        label="Stock Source"
                                        value={stockSource}
                                        maxLength={45}
                                        options={[{
                                            value: '03281E26-4BA2-42FF-A5B5-17720F128700',
                                            label: 'Swansea'
                                        }, {
                                            value: '',
                                            label: 'Mainframe'
                                        }]}
                                        onChange={(name, value) => setStockSource(value)}
                                        error={errStockSource}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <SelectBox
                                        name="setCurrency"
                                        label="Currency"
                                        value={setCurrency}
                                        maxLength={45}
                                        options={[{
                                            value: '1',
                                            label: 'British Pound'
                                        }]}
                                        onChange={(name, value) => setSetCurrency(value)}
                                        error={errSetCurrency}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <SelectBox
                                        name="contactCountry"
                                        label="Parent Group"
                                        value={contactCountry}
                                        maxLength={45}
                                        options={[{
                                            value: 'GBP',
                                            label: 'British Pound'
                                        }, {
                                            value: 'EUR',
                                            label: 'Euro'
                                        }, {
                                            value: 'USD',
                                            label: 'US Dollar'
                                        }]}
                                        onChange={(name, value) => setContactCountry(value)}
                                        error={errContactCountry}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <SwitchButton
                                        label="Group Profile"
                                        text="Set as Group Profile"
                                        name="isGroup"
                                        value={isGroup}
                                        onChange={(name, value) => setIsGroup(value)}
                                    />
                                </div>
                            </div>
                            */}
                        </FormContainer>

                        <Divider />

                        <FormContainer title="Gifting Discount Rates"
                            description={`Please select which categories this rule applies to.`}>
                            <div className="row">
                                <div className="col-sm-12">
                                    <h4 style={{ fontWeight: 'bold', color: '#333' }}>Gifting Discount Rates</h4>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th width="60%" className="text-cell">Product</th>
                                                <th width="20%">Discount Rate</th>
                                                <th width="20%">&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {discountRates.map((item, index) =>
                                                <tr key={index}>
                                                    <td className="text-cell force-wrap">{item.productName}</td>
                                                    <td className="text-left">
                                                        {item.DiscountRate === 0 ? '-' : `${item.DiscountRate.toFixed(2)}%`}
                                                    </td>
                                                    <td className="text-right">
                                                        <span className="span-link" onClick={() => editProduct(item.ID, item.DiscountRate)}>Edit</span>
                                                    </td>
                                                </tr>
                                            )}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </FormContainer>

                        {(id === "71D438AC-09EB-46FD-B34D-B0569470916A" && permClientProducts && id !== 'new') &&
                            <>
                                <Divider />
                                <FormContainer title="Applicable Categories"
                                    description={`Please select which categories this rule applies to.`}>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <CategoryTree setToken={id} />
                                        </div>
                                    </div>
                                </FormContainer>
                            </>
                        }

                        <Divider />

                        <FormContainer title="Applicable Categories"
                            description={`Please select which categories this rule applies to.`}>
                            <div className="row">
                                <div className="col-sm-12">
                                    <h4 style={{ fontWeight: 'bold', color: '#333' }}>History</h4>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th width="20%">Date</th>
                                                <th width="20%">User</th>
                                                <th width="60%" className="text-cell">Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {historyData.length === 0 &&
                                                <tr>
                                                    <td colSpan={2} className="text-center">No history available.</td>
                                                </tr>
                                            }

                                            {historyData.map((item, index) =>
                                                <tr key={index}>
                                                    <td>{moment(item.auditDate).format("DD MMM YY HH:mm")}</td>
                                                    <td>{item.firstName} {item.lastName}</td>
                                                    <td className="text-cell force-wrap">{item.auditMsg}</td>
                                                </tr>
                                            )}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </FormContainer>

                        <Divider />

                        <div className="row">
                            <div className="col-sm-8" style={{ paddingBottom: 60 }}>
                                <span className="invalid-error">{hasErrors ? 'Please check the errors above' : ''}</span>
                            </div>
                            <div className="col-sm-4 text-right" style={{ paddingBottom: 60 }}>
                                <SubmitButton
                                    text={id === 'new' ? 'Create Client' : 'Update Client'}
                                    className="btn"
                                    onClick={save}
                                />
                            </div>
                        </div>

                        {productId &&
                            <>
                                <div className="modal-shadow"></div>
                                <div className="incident-modal">
                                    <div className="ms-close">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" onClick={() => setProductId(null)}>
                                            <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clip-rule="evenodd" />
                                        </svg>

                                    </div>
                                    <div className="incident-form" style={{ padding: 20 }}>
                                        <>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <DiscountInput
                                                        name="rate"
                                                        type="number"
                                                        label="Discount rate"
                                                        value={rate}
                                                        asterix={true}
                                                        onChange={(name, value) => setDiscountRate(value)}
                                                        error={error}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 py-4">
                                                    <button className="ln-c-button ln-c-button--filled" onClick={saveRate}>Save</button>
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                </div>
                            </>
                        }


                    </NarrowContainer>
                </div>
            </div>
        </div>
    )

}

export default ClientPage;