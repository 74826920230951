import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import "./App.css";
import { Header } from './components/Header';
import Routes from './config/routes';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import UserAPI from './api/UserAPI';

function App({ navigation }) {

  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();
  const [hasPermission, setHasPermission] = useState(localStorage.getItem('userData') !== null);

  const loadUser = () => {
    if (accounts.length > 0) {
      UserAPI.getSSOUser(accounts[0].username, (data) => {
        if (data) {
          setHasPermission(true);
          localStorage.setItem('userData', JSON.stringify(data));
        } else {
          setHasPermission(false);
        }
      });
    } else {
      setHasPermission(false);
    }
  }

  useEffect(() => {
    loadUser();
  });

  return (
    <div>
      <Header navigation={navigation} loggedIn={(isAuthenticated && hasPermission)} />
      <main>
        <div className="main-container">
          <div className={(isAuthenticated && hasPermission) ? 'single-column' : `login-screen`}>
            <Routes navigation={navigation} loggedIn={(isAuthenticated && hasPermission)} />
          </div>
        </div>
      </main>
    </div>
  )

}

export default withRouter(App);