import React from 'react';
import { withRouter } from 'react-router-dom';
import { SubmitButton } from '../../components/Button';
import { FormContainer } from '../../components/Container';
import { TextArea } from '../../components/TextInput';
import NotesAPI from '../../api/NotesAPI';
import moment from 'moment';
import UserAPI from '../../api/UserAPI';

class Notes extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: props.id,
            notes: [],
            authorId: '',
            noteBody: '',
        }

        this.addNote = this.addNote.bind(this);

    }

    componentDidMount() {
        UserAPI.getUserData((data) => {
            if (data) {
                console.log(data);
                this.setState({
                    authorId: data.userToken,
                });
                this.loadNotes();
            }
        })
    }

    loadNotes() {
        NotesAPI.getNotes(this.state.id, (data) => {
            this.setState({
                notes: data,
                noteBody: '',
            });
        });
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
        });
    }

    addNote() {

        if (this.state.noteBody !== '') {
            let payload = {
                linkedId: this.state.id,
                authorId: this.state.authorId,
                noteBody: this.state.noteBody,
            }
            NotesAPI.addNote(payload, (data) => {
                this.loadNotes();
            });
        }

    }

    render() {
        return (
            <FormContainer title="Notes & History"
                description={`Related notes and audit logs.`}>
                <div className="row">
                    <div className="col-sm-12">

                        <table>
                            <thead>
                                <tr>
                                    <th width="30%">Date/Author</th>
                                    <th width="70%" className="text-cell">Note</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.notes.length === 0 &&
                                    <tr>
                                        <td colSpan="2" className="text-center">No notes have been added.</td>
                                    </tr>
                                }
                                {this.state.notes.map((item, index) =>
                                    <tr key={index}>
                                        <td style={{ lineHeight: 1.5, paddingTop: 10, paddingBottom: 10, verticalAlign: 'top' }}>
                                            {item.authorName}<br />
                                            <small>{moment(item.noteTime).format("ddd DD MMM YY @ HH:mm")}</small>
                                        </td>
                                        <td className="text-cell" style={{ lineHeight: 1.5, paddingTop: 10, paddingBottom: 10, verticalAlign: 'top' }}>{item.noteBody}</td>
                                    </tr>
                                )}

                            </tbody>
                        </table>

                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <TextArea
                            name="noteBody"
                            type="text"
                            label="Add New Note"
                            value={this.state.noteBody}
                            rows={3}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <SubmitButton
                            text='Add Note'
                            className="btn btn-secondary"
                            onClick={this.addNote}
                        />
                    </div>
                </div>

            </FormContainer>
        )
    }
};

export default withRouter(Notes);