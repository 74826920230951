import React from 'react';
import { withRouter, Link } from 'react-router-dom';

class Tabs extends React.Component {

    render() {

        const path = this.props.location.pathname;

        return (
            <div className="tabs">
                {this.props.tabs.map((tab, index) =>
                    <div className={path === tab.url ? 'tab active' : 'tab'}>
                        <Link to={tab.url}>{tab.label}</Link>
                    </div>
                )}
            </div>
        )
    }
};

export default withRouter(Tabs);