import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FormContainer, NarrowContainer } from '../../components/Container';
import { Title } from '../../components/Title';
import { Divider } from '../../components/Divider';
import { SubmitButton, BackButton, SwitchButton } from '../../components/Button';
import { TextInput } from '../../components/TextInput';
import { SelectBox } from '../../components/SelectBox';
import ClientAPI from '../../api/ClientAPI';
import RuleAPI from '../../api/RuleAPI';
import { CategoryTree } from '../../components/CategoryTree';
import { Notes } from '../../components/Notes';

function RulePage({ navigation }) {

    const { id } = useParams();
    const history = useHistory();

    const [setToken, setSetToken] = useState('');
    const [ruleName, setRuleName] = useState('');
    const [clients, setClients] = useState([]);
    const [ruleClients, setRuleClients] = useState([]);
    const [pricingMethod, setPricingMethod] = useState('');
    const [ruleAmount, setRuleAmount] = useState(0);
    const [ruleFormat, setRuleFormat] = useState('');
    const [cappedRetail, setCappedRetail] = useState('');
    const [fixedAmount, setFixedAmount] = useState(0);
    const [errRuleName, setErrRuleName] = useState('');
    const [errRuleAmount, setErrRuleAmount] = useState('');
    const [errRuleFormat, setErrRuleFormat] = useState('');
    const [errPricingMethod, setErrPricingMethod] = useState('');
    const [maxDiscount, setMaxDiscount] = useState(0);
    const [errMaxDiscount, setErrMaxDiscount] = useState('');
    const [maxMargin, setMaxMargin] = useState(0);
    const [errMaxMargin, setErrMaxMargin] = useState('');
    const [treeMethod, setTreeMethod] = useState(0);

    useEffect(() => {
        loadClients();
        if (id !== 'new') {
            loadRule();
            loadRuleClients();
        }
    }, []);

    const loadRuleClients = () => {
        RuleAPI.getRuleClients(id, (data) => {
            setRuleClients(data);
        });
    }

    const loadRule = () => {
        RuleAPI.getRule(id, (data) => {
            console.log("getRule()", data);
            setRuleName(data.ruleName);
            setPricingMethod(data.pricingMethod);
            setRuleAmount(data.ruleAmount);
            setRuleFormat(data.ruleFormat);
            setCappedRetail(data.cappedRetail);
            setFixedAmount(data.fixedAmount);
            setMaxDiscount(data.maxDiscount);
            setMaxMargin(data.maxMargin);
            setTreeMethod(data.treeMethod);
        });
    }

    const save = () => {

        var _ok = true;
        if (ruleName === '') {
            _ok = false;
            setErrRuleName('Please enter a rule name');
        }
        if (ruleAmount === '' || parseFloat(ruleAmount) <= 0) {
            _ok = false;
            setErrRuleAmount('Please enter an amount');
        }
        if (ruleFormat === '') {
            _ok = false;
            setErrRuleFormat('Please enter a format');
        }
        if (pricingMethod === '' || parseInt(pricingMethod) <= 0) {
            _ok = false;
            setErrPricingMethod('Please select a method');
        }
        if (pricingMethod === "4" && maxDiscount === '') {
            _ok = false;
            setErrMaxDiscount('Please enter a value');
        }
        if (pricingMethod === "4" && (parseFloat(maxDiscount) < 0 || parseFloat(maxDiscount) > 1)) {
            _ok = false;
            setErrMaxDiscount('Invalid value');
        }
        if (pricingMethod === "4" && (parseFloat(maxMargin) < 0 || parseFloat(maxMargin) > 1)) {
            _ok = false;
            setErrMaxMargin('Invalid value');
        }

        if (_ok) {
            let payload = {
                ruleID: id === "new" ? 0 : id,
                pricingMethod: pricingMethod,
                ruleAmount: parseFloat(ruleAmount),
                ruleFormat: ruleFormat,
                fixedPrice: 0,
                fixedAmount: fixedAmount,
                ruleName: ruleName,
                setToken: '',
                cappedRetail: cappedRetail,
                scheduled: false,
                periodFrom: '',
                periodTo: '',
                maxDiscount: maxDiscount,
                maxMargin: maxMargin,
                treeMethod: treeMethod,
            }

            RuleAPI.updRule(payload, (data) => {
                if (id === "new") {
                    history.push(`/rules/${data.ruleID}`);
                } else {
                    history.push('/rules');
                }
            })

        }

    }

    const delRuleClient = (client) => {
        RuleAPI.delRuleClient(id, client, (data) => {
            //this.loadRuleClients();
            history.push(`/rules/${id}`);
        });
    }

    const loadClients = () => {
        ClientAPI.getClients((data) => {
            var _arr = [];
            _arr.push({
                value: '',
                label: 'Select Client...',
            });
            if (data.dataSets) {
                for (var i = 0; i < data.dataSets.length; i++) {
                    _arr.push({
                        value: data.dataSets[i].setToken,
                        label: data.dataSets[i].setName + ' (' + data.dataSets[i].businessAccNo + ')',
                    });
                }
                setClients(_arr);
            }
        });
    }

    const addClient = () => {
        RuleAPI.addRuleClient(id, setToken, (data) => {
            //this.loadRuleClients();
            history.push(`/rules/${id}`);
        });
    }

    return (
        <div className="container-fluid">
            <div className="row row-eq-height">
                <div className="col-sm-12 content">
                    <NarrowContainer>

                        <BackButton url={'/rules'} title="Back" />
                        <Title title={id === 'new' ? "New Price Rule" : "Edit Price Rule"} />

                        <FormContainer title="Rule Information"
                            description={`Please enter the rule details.`}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <TextInput
                                        name="ruleName"
                                        type="text"
                                        label="Rule Name"
                                        value={ruleName}
                                        maxLength={45}
                                        asterix={true}
                                        onChange={(name, value) => setRuleName(value)}
                                        error={errRuleName}
                                    />
                                </div>
                            </div>
                        </FormContainer>

                        <Divider />

                        <FormContainer title="Rule Parameters"
                            description={`Please enter the rule parameters.`}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <SelectBox
                                        name="pricingMethod"
                                        label="Method"
                                        value={pricingMethod}
                                        maxLength={45}
                                        options={[{
                                            value: '',
                                            label: 'Select...'
                                        }, {
                                            value: 2,
                                            label: 'Retail Minus'
                                        }, {
                                            value: 4,
                                            label: 'Cost Plus'
                                        }]}
                                        onChange={(name, value) => setPricingMethod(value)}
                                        error={errPricingMethod}
                                    />
                                </div>
                                {(pricingMethod !== "4" && pricingMethod !== 4) &&
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="ruleAmount"
                                            type="number"
                                            label={pricingMethod === 4 ? "Mark Up" : "Alteration"}
                                            value={ruleAmount}
                                            maxLength={45}
                                            asterix={true}
                                            onChange={(name, value) => setRuleAmount(value)}
                                            error={errRuleAmount}
                                        />
                                    </div>
                                }
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <SelectBox
                                        name="ruleFormat"
                                        label="Format"
                                        value={ruleFormat}
                                        disabled={pricingMethod === 4 ? true : false}
                                        maxLength={45}
                                        options={[{
                                            value: '',
                                            label: 'Select...'
                                        }, {
                                            value: 1,
                                            label: 'Pounds'
                                        }, {
                                            value: 2,
                                            label: 'Percentage'
                                        }]}
                                        onChange={(name, value) => setRuleFormat(value)}
                                        error={errRuleFormat}
                                    />
                                </div>
                                {(pricingMethod === "3" || pricingMethod === 3) &&
                                    <div className="col-sm-6">
                                        <SwitchButton
                                            label="Capped"
                                            text="Capped at Retail Price"
                                            name="cappedRetail"
                                            value={cappedRetail}
                                            onChange={(name, value) => setCappedRetail(value)}
                                        />
                                    </div>
                                }
                                {(pricingMethod === "4" || pricingMethod === 4) &&
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="maxMargin"
                                            type="number"
                                            label={"Percentage above RRP Cost Plus (%)"}
                                            value={maxMargin}
                                            maxLength={45}
                                            asterix={true}
                                            onChange={(name, value) => setMaxMargin(value)}
                                            error={errMaxMargin}
                                            notes="(format: 0.15 for 15%)"
                                        />
                                    </div>
                                }
                                {(pricingMethod === "4" || pricingMethod === 4) &&
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="maxDiscount"
                                            type="number"
                                            label={"Maximum Discount (%)"}
                                            value={maxDiscount}
                                            maxLength={45}
                                            asterix={true}
                                            onChange={(name, value) => setMaxDiscount(value)}
                                            error={errMaxDiscount}
                                            notes="(format: 0.15 for 15%)"
                                        />
                                    </div>
                                }
                            </div>
                        </FormContainer>

                        {id !== 'new' &&
                            <Divider />
                        }

                        {id !== 'new' &&
                            <FormContainer title="Applicable Clients"
                                description={`Please select which clients this rule applies to.`}>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th width="20%">Account Number</th>
                                                    <th width="70%">Client Name</th>
                                                    <th width="10%">&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {ruleClients.map((item, index) =>
                                                    <tr key={index}>
                                                        <td>{item.businessAccNo}</td>
                                                        <td>{item.setName}</td>
                                                        <td><div className="delete-item" onClick={(e) => { if (window.confirm('Are you sure you wish to delete this item?')) delRuleClient(item.setToken) }}><i className="far fa-times-circle"></i></div></td>
                                                    </tr>
                                                )}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <SelectBox
                                            name="setToken"
                                            label="Add Client"
                                            value={setToken}
                                            maxLength={45}
                                            options={clients}
                                            onChange={(name, value) => setSetToken(value)}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <SubmitButton
                                            text="Add Client"
                                            className="btn btn-secondary"
                                            onClick={addClient}
                                        />
                                    </div>
                                </div>
                            </FormContainer>
                        }

                        {id !== 'new' &&
                            <Divider />
                        }

                        {id !== 'new' &&
                            <FormContainer title="Applicable Categories"
                                description={`Please select which categories this rule applies to.`}>

                                <div className="row">
                                    <div className="col-sm-12">

                                        <div className="tree-frame">
                                            <div className="tree-group">
                                                <div style={{ cursor: 'pointer' }} onClick={() => setTreeMethod(1)} title="Add"><i className={treeMethod === 1 ? `far fa-check-circle` : `far fa-circle`}></i> Retail Categories</div>
                                            </div>
                                            {treeMethod === 1 &&
                                                <div className="tree-block">
                                                    <CategoryTree rule={id} isTradingCategory={false} />
                                                </div>
                                            }

                                            <div className="tree-group">
                                                <div style={{ cursor: 'pointer' }} onClick={() => setTreeMethod(2)} title="Add"><i className={treeMethod === 2 ? `far fa-check-circle` : `far fa-circle`}></i> Trading Categories</div>
                                            </div>
                                            {treeMethod === 2 &&
                                                <div className="tree-block">
                                                    <CategoryTree rule={id} isTradingCategory={true} />
                                                </div>
                                            }
                                        </div>

                                    </div>
                                </div>
                            </FormContainer>
                        }

                        {id !== 'new' &&
                            <Divider />
                        }

                        {id !== 'new' &&
                            <Notes id={id} />
                        }

                        <Divider />

                        <div className="row">
                            <div className="col-sm-8" style={{ paddingBottom: 60 }}>
                                <span className="invalid-error">&nbsp;</span>
                            </div>
                            <div className="col-sm-4 text-right" style={{ paddingBottom: 60 }}>
                                <SubmitButton
                                    text={id === 'new' ? 'Create Rule' : 'Update Rule'}
                                    className="btn"
                                    onClick={save}
                                />
                            </div>
                        </div>

                    </NarrowContainer>
                </div>
            </div>
        </div>
    )

}

export default RulePage;