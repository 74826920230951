import React from 'react';
import { withRouter, Link } from 'react-router-dom';

function MainLink({ url, icon, title, count = 0 }) {

    return (
        <Link to={url}>{title}{count ? <span>{count}</span> : ''}</Link>
    )

};

export default withRouter(MainLink);