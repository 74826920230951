import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import { Container } from '../../components/Container';
import { Title } from '../../components/Title';
import { SubmitButton, BackButton } from '../../components/Button';
import { TextInput } from '../../components/TextInput';
import { SelectBox } from '../../components/SelectBox';
import UserAPI from '../../api/UserAPI';
import ConsoleAPI from '../../api/ConsoleAPI';
import { OrderSidebar } from '../../components/Sidebar';
import AttachmentAPI from '../../api/AttachmentAPI';

function OrderFundsPage({ navigation }) {

    const { id } = useParams();
    const history = useHistory();

    const [orderNumber, setOrderNumber] = useState('');
    const [orderDate, setOrderDate] = useState('');
    const [clientName, setClientName] = useState('');
    const [orderStatus, setOrderStatus] = useState(0);
    const [confirmedBy, setConfirmedBy] = useState('');
    const [confirmedByName, setConfirmedByName] = useState('');
    const [fundsReceived, setFundsReceived] = useState('');
    const [transRef, setTransRef] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [errFundsReceived, setErrFundsReceived] = useState('');
    const [errPaymentMethod, setErrPaymentMethod] = useState('');
    const [attachments, setAttachments] = useState([]);
    const [selectedFile, setSelectedFile] = useState('');

    useEffect(() => {

        loadOrder();
        loadAttachments();

        UserAPI.getUserData((data) => {
            if (data) {
                setConfirmedBy(data.userToken);
                setConfirmedByName(data.firstName + ' ' + data.lastName);
            }
        })

    }, []);

    const loadAttachments = () => {
        AttachmentAPI.getAttachments(id, (data) => {
            setAttachments(data);
        });
    }

    const attach = () => {
        var formData = new FormData()
        formData.append('file', selectedFile);
        AttachmentAPI.addAttachment(id, formData, (res) => {
            loadAttachments();
        });
    }

    const onChangeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
    }

    const loadOrder = () => {
        ConsoleAPI.getOneOffOrder(id, (data) => {
            console.log(data);
            setClientName(data.clientName);
            setOrderNumber(data.orderNumber);
            setOrderDate(data.orderDate);
            setOrderStatus(data.orderStatus);

            if (data.paymentMethod) {
                setPaymentMethod(data.paymentMethod);
            }

            if (data.fundsReceived) {
                setFundsReceived(moment(data.fundsReceived).format("YYYY-MM-DD"));
            }

            if (data.transRef) {
                setTransRef(data.transRef);
            }
            //setClientPO(data.clientPO);
            //setAccountNumber(data.accountNumber);
            //setContactName(data.contactName);
            //setContactEmail(data.contactEmail);
            //setContactLine1(data.contactLine1);
            //setContactLine2(data.contactLine2);
            //setContactTown(data.contactTown);
            //setContactPostcode(data.contactPostcode);
            //setRecipientName(data.recipientName);
            //setRecipientEmail(data.recipientEmail);
            //setRecipientLine1(data.recipientLine1);
            //setRecipientLine2(data.recipientLine2);
            //setRecipientTown(data.recipientTown);
            //setRecipientPostcode(data.recipientPostcode);
            //setCopyAddress(data.copyAddress);
            //setTotalCost(data.totalCost);
            //setDiscountValue(data.discountValue);
            //setDiscountRate(data.discountRate);
            //setSainsburysDiscountRate(data.sainsburysDiscountRate);
            //setSainsburysDiscountValue(data.sainsburysDiscountValue);
            //setDiscount(data.discount);
        });
    }

    const confirm = () => {

        var _ok = true;
        if (paymentMethod === 0) {
            _ok = false;
            //setHasErrors(true);
            setErrPaymentMethod('Please select the payment method');
        }
        /*
        if (transRef === '') {
            _ok = false;
            this.setState({
                hasErrors: true,
                errTransRef: 'Please enter the transaction reference'
            });
        }
        */
        var date = moment(fundsReceived);
        if (!date.isValid()) {
            _ok = false;
            //setHasErrors(true);
            setErrFundsReceived('Please select a valid date');
        }
        /*
        if (attachments.length === 0) {
            _ok = false;
            this.setState({
                hasErrors: true,
                fileError: true,
            });
        }
        */

        if (_ok) {

            let payload = {
                ID: id,
                confirmedBy: confirmedBy,
                paymentMethod: paymentMethod,
                fundsReceived: fundsReceived,
                transRef: transRef
            }

            ConsoleAPI.updOneOffOrderPayment(payload, (data) => {
                history.push(`/orders/${id}`);
            })

        }

    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12 content">
                    <BackButton title="Back" url={`/orders/${id}`} />
                    <Title title={`DPI${orderNumber} - ${clientName}`} />
                    <div>
                        {orderStatus === 3 ? <span className="grey-warning" style={{ marginTop: -20, marginRight: 20 }}>Despatched</span> : orderStatus === 2 ? <span className="grey-warning" style={{ marginTop: -20, marginRight: 20 }}>Order Complete</span> : orderStatus === 5 ? <span className="red-warning" style={{ marginTop: -20, marginRight: 20 }}>Payment Made</span> : <span className="yellow-warning" style={{ marginTop: -20, marginRight: 20 }}>Awaiting Payment</span>} Created: {moment(orderDate).format("dddd DD MMMM YYYY - HH:mm")}</div>

                </div>
            </div>
            <div className="row row-eq-height">
                <div className="col-sm-8 content">

                    <Container>
                        <div className="row">
                            <div className="col-sm-12">
                                <h4 style={{ fontWeight: 'bold', color: '#333' }}>Confirm Funds Received</h4>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <SelectBox
                                            name="paymentMethod"
                                            label="Payment Method"
                                            placeholder="Select..."
                                            value={paymentMethod}
                                            maxLength={45}
                                            asterix={true}
                                            options={[{
                                                value: '1',
                                                label: 'BACS'
                                            }, {
                                                value: '2',
                                                label: 'Credit/Debit Card'
                                            }]}
                                            onChange={(name, value) => setPaymentMethod(value)}
                                            error={errPaymentMethod}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="confirmedByName"
                                            type="text"
                                            label="Confirmed By"
                                            value={confirmedByName}
                                            disabled={true}
                                            maxLength={45}
                                            asterix={true}
                                            onChange={(name, value) => setConfirmedByName(value)}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="fundsReceived"
                                            type="date"
                                            label="Date Received"
                                            value={fundsReceived}
                                            maxLength={45}
                                            asterix={true}
                                            onChange={(name, value) => setFundsReceived(value)}
                                            error={errFundsReceived}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="transRef"
                                            type="text"
                                            label="Transaction Reference"
                                            placeholder="Enter reference..."
                                            value={transRef}
                                            maxLength={45}
                                            onChange={(name, value) => setTransRef(value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>

                    <Container>
                        <div className="row">
                            <div className="col-sm-12">
                                <h4 style={{ fontWeight: 'bold', color: '#333' }}>Attached Files/Images</h4>
                                <div className="row">
                                    <div className="col-sm-12">
                                        {attachments.length > 0 &&
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>Added</th>
                                                                <th>Filename</th>
                                                                <th>&nbsp;</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {attachments.map((item, index) =>
                                                                <tr key={index}>
                                                                    <td>{moment(item.dateAdded).format("ddd DD MMM YY @ HH:mm")}</td>
                                                                    <td><a target="_blank" rel="noopener noreferrer" href={`https://data.afbdata.com/uploads/${id}/${item.fileName}`}>{item.fileName}</a></td>
                                                                    <td><div className="delete-item" onClick={(e) => { if (window.confirm('Are you sure you wish to delete this item?')) this.deleteAttachment(item.ID) }}><i className="far fa-times-circle"></i></div></td>
                                                                </tr>
                                                            )}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        }
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <input type="file" name="file" className="form-control" onChange={onChangeHandler} />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <SubmitButton
                                                    text={'Upload Attachment'}
                                                    className="btn btn-secondary"
                                                    onClick={attach}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>

                    <div className="row">
                        <div className="col-sm-12 text-right">
                            <SubmitButton
                                text="Confirm Funds Received"
                                className="btn"
                                onClick={confirm}
                            />
                        </div>
                    </div>

                </div>
                <div className="col-sm-4 content">

                    <OrderSidebar id={id} />

                </div>
            </div>

        </div>
    )

}

export default OrderFundsPage;